import React, { useEffect } from 'react'
import { InnerHeader } from 'src/UIComponents/Template'
import HeaderInfo from 'src/Components/HeaderInfo/HeaderInfo'
import { getKPIsSaleInfo, getCardTitles } from 'src/Utils/KPIsInfo'
import {
  getTransactionsAverageTicket,
  getTransactionsChargedBackAmount,
  getTransactionsDisputedAmount,
  getTransactionsFailedAmount,
  getTransactionsSucceededAmount
} from 'src/Actions/kpi'
import { matchPath, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const Metrics = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    seller,
    transactions,
    period,
    marketplace
  } = useSelector(({ kpi, context }) => ({
    ...kpi,
    ...context
  }))

  useEffect(() => {
    dispatch(getTransactionsAverageTicket())
    dispatch(getTransactionsSucceededAmount())
    dispatch(getTransactionsChargedBackAmount())
    dispatch(getTransactionsDisputedAmount())
    dispatch(getTransactionsFailedAmount())
  }, [period, seller, marketplace])

  const mountInfoResume = () => {
    const kpisKeys = Object.keys(transactions)

    const infoResume = new Map(kpisKeys.map((key) => {
      const { request, error, amount } = transactions[key]

      const info = getKPIsSaleInfo({
        type: 'transaction', key, amount, error, request
      })

      if (error && error.status !== 404) {
        return [key, {
          icon: '/assets/icons/actions/reload.svg',
          title: 'Desculpe tivemos um problema',
          info: 'Clique aqui para recarregar.',
          isEmpty: true,
          onClick: () => {
            if (key === 'averageTicket') dispatch(getTransactionsAverageTicket())
            if (key === 'succeeded') dispatch(getTransactionsSucceededAmount())
            if (key === 'chargedBack') dispatch(getTransactionsChargedBackAmount())
            if (key === 'dispute') dispatch(getTransactionsDisputedAmount())
            if (key === 'failed') dispatch(getTransactionsFailedAmount())
          }
        }]
      }

      const isEmpty = (error && error.status === 404) || amount === 0

      return [key, {
        label: getCardTitles('transaction', key),
        info,
        isEmpty,
        hasNoDetail: key === 'averageTicket',
        onClick: () => {
          if (key === 'averageTicket') return null
          const { pathname, search } = document.location
          if (!matchPath(`${pathname}${search}`, {
            path: `vendas/kpi?type=${key}`,
            exact: true,
            strict: false
          })) {
            history.push(`vendas/kpi?type=${key}`)
          }
        }
      }]
    }))

    return Array.from(infoResume.values())
  }

  return (
    <div id='headerinfo'>
      <InnerHeader>
        <HeaderInfo infoResume={mountInfoResume()} title='vendas' showSellerSelector />
      </InnerHeader>
    </div>
  )
}

export { Metrics }
