import React from 'react'
import { useSelector } from 'react-redux'

import {
  ContainerEmptyState } from '../styles'

import Label from 'src/UIComponents/Label/Label'
import Card from 'src/UIComponents/Card/Card'

import { darkGray, darkerGray, smallText } from 'src/Styles/settings/Constants'

import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import ErrorStateCard from 'src/Components/ErrorStateCard/ErrorStateCard'

import ReloadIcon from 'src/Assets/icons/actions/reload.svg'
import Sales from 'src/Assets/icons/others/sales.svg'

import SellersRankingByAmount from 'src/UIComponents/KPIDetails/SellersRankingByAmount'

import { allTogether } from 'src/Actions/kpi'

const TopHitsSellersByAmount = (props) => {
  const { topHitsSellers, request, error, allRequests, statusCode } =
    useSelector(
      ({
        kpi: {
          allTogetherKPIs: { allRequests },
          transactions: { succeeded }
        }
      }) => ({
        topHitsSellers: succeeded.topHitsSellers,
        request: succeeded.request,
        error: succeeded.error,
        statusCode: succeeded.error && succeeded.error.status,
        allRequests
      })
    )

  const isLoading = Boolean(allRequests || request)
  return (
    <Card {...props}>
      {!isLoading &&
      (!topHitsSellers ||
        (topHitsSellers && topHitsSellers.length === 0) ||
        error.status === 404) ? (
        <>
          <Label
            uppercase
            bold
            color={darkGray}
            fontSize={smallText}
            textAlign='center'
          >
            {'Empresas com maior '}
            <Label uppercase bold fontSize={smallText}>
              volume de vendas
            </Label>
          </Label>
          <ContainerEmptyState>
            {statusCode === 404 && (
              <EmptyState
                iconSrc={Sales}
                iconColor={darkerGray}
                iconSize='small'
                description='Não existem empresas com volume<br />de vendas para apresentar :('
              />
            )}
            {statusCode !== 404 && (
              <ErrorStateCard
                iconSrc={ReloadIcon}
                iconColor={darkerGray}
                color={darkerGray}
                iconSize='small'
                action={allTogether}
              />
            )}
          </ContainerEmptyState>
        </>
        ) : (
          <SellersRankingByAmount
            title='Empresas com maior '
            highligth='volume de vendas'
            items={topHitsSellers}
            isLoading={isLoading}
            noCard
          />
        )}
    </Card>
  )
}

export default TopHitsSellersByAmount
