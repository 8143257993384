import React from 'react'
import Filter from 'src/UIComponents/Filter/Filter'
import DateInput from 'src/UIComponents/Date/DateInput'
import { RangeSelector } from 'src/UIComponents/Range'
import PropTypes from 'prop-types'

const TransferFilter = (props) => <Filter defaultSearch={props.search} {...props} />

TransferFilter.propTypes = {
  search: PropTypes.string,
  filters: PropTypes.array
}

TransferFilter.defaultProps = {
  filters: [
    {
      placeholder: 'Data',
      component: DateInput,
      accessor: 'date_range'
    },
    {
      placeholder: 'Status',
      accessor: 'status',
      isMultiSelect: true,
      transformer: {
        format: (value) => {
          return typeof value === 'string' ? (value.length > 0 ? value.split(',') : undefined) : value
        },
        parse: (value) => {
          if (value && Array.isArray([...value])) {
            const selectedStatuses = [...value]
            const values = selectedStatuses.map(({ value }) => value)
            return values.join(',')
          }
          return value
        }
      },
      options: [
        { label: 'Paga', value: 'succeeded' },
        { label: 'Cancelada', value: 'canceled' },
        { label: 'Pendente', value: 'pending' },
        { label: 'Falhada', value: 'failed' },
        { label: 'Confirmada', value: 'confirmed' },
        { label: 'Agendada', value: 'scheduled' },
        { label: 'Pendente Banco', value: 'pending_bank' }
      ]
    },
    {
      placeholder: 'Tipo',
      accessor: 'type',
      isMultiSelect: true,
      transformer: {
        format: (value) => {
          return typeof value === 'string' ? (value.length > 0 ? value.split(',') : undefined) : value
        },
        parse: (value) => {
          if (value && Array.isArray([...value])) {
            const selectedPaymentTypes = [...value]
            const values = selectedPaymentTypes.map(({ value }) => value)
            return values.join(',')
          }
          return value
        }
      },
      options: [
        { label: 'Entre contas digitais', value: 'transfer' },
        { label: 'Automática', value: 'payout_automatic|cash_out_automatic' },
        { label: 'Enviada', value: 'payout_manual|cash_out|transfer_and_payout' },
        { label: 'Recebida', value: 'cash_in' }
      ]
    },
    {
      placeholder: 'Valor',
      component: RangeSelector,
      accessor: 'amount_range'
    }
  ]
}

export { TransferFilter }
