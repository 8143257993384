import React, { useEffect } from 'react'
import { getSellersByStatus } from 'src/Actions/kpi'
import { getCardTitles, getKPIsSaleInfo } from 'src/Utils/KPIsInfo'
import { useDispatch, useSelector } from 'react-redux'
import HeaderInfo from 'src/Components/HeaderInfo/HeaderInfo'
import { InnerHeader } from 'src/UIComponents/Template'

const Metrics = () => {
  const dispatch = useDispatch()
  const { sellers, period, marketplace } = useSelector(({ kpi, context }) => ({
    sellers: kpi.sellers,
    period: kpi.period,
    marketplace: context.marketplace
  }))

  useEffect(() => {
    dispatch(getSellersByStatus('enabled'))
    dispatch(getSellersByStatus('pending'))
    dispatch(getSellersByStatus('denied'))
  }, [period, marketplace])

  const mountInfoResume = () => {
    const kpisKeys = Object.keys(sellers)

    const infoResume = new Map(
      kpisKeys.map((key) => {
        const { request, error, count } = sellers[key]

        const info = getKPIsSaleInfo({
          type: 'seller',
          key,
          count,
          error,
          request
        })

        if (error && error.status !== 404) {
          return [
            key,
            {
              icon: '/assets/icons/actions/reload.svg',
              title: 'Desculpe tivemos um problema',
              info: 'Clique aqui para recarregar.',
              isEmpty: true,
              onClick: () => {
                if (key === 'enabled') return dispatch(getSellersByStatus('enabled'))
                if (key === 'pending') return dispatch(getSellersByStatus('pending'))
                if (key === 'denied') return dispatch(getSellersByStatus('denied'))
              }
            }
          ]
        }

        const isEmpty = (error && error.status === 404) || count === 0

        return [
          key,
          {
            label: getCardTitles('seller', key),
            info,
            isEmpty,
            hasNoDetail: true
          }
        ]
      })
    )

    return Array.from(infoResume.values())
  }
  return (
    <InnerHeader>
      <HeaderInfo title='Estabelecimentos' infoResume={mountInfoResume()} />
    </InnerHeader>
  )
}

export { Metrics }
