import styled, { css } from 'styled-components'
import Card from 'src/UIComponents/Card/Card'

const Grid = css`
  width: 100%;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

  & > .span2 {
    grid-column-end: span 2;
  }
`

export const DashboardAnalytics = styled.div`
  // START SHOW AS A FULLSCREEN
  /* height: calc(100vh - 383px); // 143px (header) + 240px (headerinfo) */
  // END SHOW AS A FULLSCREEN

  padding: 0 20px;
  // compensation of headerinfo margin-bottom
  margin-top: -36px;

  ${Grid}
`

export const DashboardAnalyticsInfoResume = styled.div`
  ${Grid}
`

export const CardStyled = styled(Card)`
  justify-content: space-between;

  ${({ notData }) => notData && css`
    justify-content: flex-start;
  `}
`

export const ContainerEmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
