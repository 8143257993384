import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'
import { matchPath, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getKPIsSaleInfo, getCardTitles } from 'src/Utils/KPIsInfo'
import {
  getTransactionsAverageTicket,
  getTransactionsChargedBackAmount, getTransactionsFailedAmount,
  getTransactionsSucceededAmount,
  allTogether
} from 'src/Actions/kpi'

import { getLastTransactions } from '../../Actions/transactions'

import HeaderInfo from 'src/Components/HeaderInfo/HeaderInfo'
import { InnerHeader } from 'src/UIComponents/Template'
import Icon from 'src/UIComponents/Icon/Icon'
import { Beacon } from '../../Components/OnboardingHints/Beacon'

const Metrics = ({ customInfoResume }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showCustom, setShowCustom] = useState(true)

  const {
    seller,
    transactions,
    period,
    marketplaceId
  } = useSelector(({ kpi, context, marketplace }) => ({
    ...kpi,
    ...context,
    marketplaceId: (context.marketplace && context.marketplace.id) || marketplace.id
  }))

  useEffect(() => {
    // TODO: Precisa refatorar essa parte do código, pois ela foi criada apenas para solucionar um problema pontual no #suporte-dashboard.
    // Precisa pensar em uma solução para verificar se o marketplace só transiciona boleto/pix e mudar a query que é enviada para o Elastic.
    // Fizemos assim apenas para solucionar o problema momentaneamente pois não possuímos tantos marketplaces com transações que não possua cartão.
    if (marketplaceId === 'e982c77e69e74d968de1a98eb99bb05f') {
      dispatch(allTogether(false))
    } else {
      dispatch(allTogether())
    }

    dispatch(getLastTransactions())
  }, [period, seller])

  const mountInfoResume = () => {
    const kpisKeys = Object.keys(transactions)

    const infoResume = new Map(kpisKeys.map((key) => {
      const { request, error, amount } = transactions[key]

      const info = getKPIsSaleInfo({
        type: 'transaction', key, amount, error, request
      })

      if (error && error.status !== 404) {
        return [key, {
          icon: '/assets/icons/actions/reload.svg',
          title: 'Desculpe tivemos um problema',
          info: 'Clique aqui para recarregar.',
          isEmpty: true,
          onClick: () => {
            if (key === 'averageTicket') dispatch(getTransactionsAverageTicket())
            if (key === 'succeeded') dispatch(getTransactionsSucceededAmount())
            if (key === 'chargedBack') dispatch(getTransactionsChargedBackAmount())
            if (key === 'failed') dispatch(getTransactionsFailedAmount())
          }
        }]
      }

      const isEmpty = (error && error.status === 404) || amount === 0

      return [key, {
        label: getCardTitles('transaction', key),
        info,
        isEmpty,
        hasNoDetail: key === 'averageTicket',
        onClick: () => {
          if (key === 'averageTicket') return null
          const { pathname, search } = document.location
          if (!matchPath(`${pathname}${search}`, {
            path: `${seller?.id ? 'visao-geral' : '/home'}/kpi?type=${key}`,
            exact: true,
            strict: false
          })) {
            history.push(`${seller?.id ? 'visao-geral' : '/home'}/kpi?type=${key}`)
          }
        }
      }]
    }))

    return Array.from(infoResume.values())
  }

  const toggleCustomInfo = () => setShowCustom(showCustom => !showCustom)

  return (
    <div id='headerinfo'>
      <InnerHeader>
        <HeaderInfo
          infoResume={mountInfoResume()}
          customInfo={showCustom && customInfoResume}
          actions={
            customInfoResume && (
              <ToggleVisionLink data-test='home-btn-change-vision' onClick={toggleCustomInfo}>
                {!showCustom ? (
                  <span>
                    Alterar para visão <strong>Gráfica</strong>
                  </span>
                ) : (
                  <span>
                    Alterar para visão de <strong>KPIs</strong>
                  </span>
                )}
                <WrapperIcon>
                  <CustomIcon
                    src='/assets/icons/system/charts.svg'
                  />
                </WrapperIcon>
                <Beacon hint='mudar-visao-kpi' wrapperProps={{ top: '30px', right: '20px' }} />
              </ToggleVisionLink>
            )
          }
          title={seller?.id ? 'visão geral' : 'posição de análise consolidada'}
          showSellerSelector
        />
      </InnerHeader>
    </div>
  )
}

Metrics.propTypes = {
  customInfoResume: PropTypes.node
}

export { Metrics }

const ToggleVisionLink = styled.div`
  font-size: 14px;
  cursor: pointer;
  padding: 10px;
  display: flex;
`

const WrapperIcon = styled.div`
  margin-top: -3px;
`

const CustomIcon = styled(Icon)`
  display: inline-block;
  padding-left: 10px;
  svg {
    cursor: pointer;
    /* transform: translate(-100%, 100%); */
    /* transition: transform .3s ease-in-out; */
  }
`
