import React, { Fragment, Component } from 'react'

import { getQuerystring } from 'src/Helpers/Router/Tools'
import AuthStatusMessage from 'src/UIComponents/AuthBox/AuthStatusMessage'

export default class TokenExpiredContainer extends Component {
  render () {
    const querystring = getQuerystring()
    const title = querystring.origin === 'recuperar-senha'
      ? <><strong>Alterar</strong> Senha</>
      : querystring.origin === 'criar-conta'
        ? <><strong>Criar</strong> Conta</>
        : <><strong>Token</strong> Expirado</>

    const message = querystring.origin === 'recuperar-senha'
      ? <>Poxa, esse link já não funciona mais... Mas não tem problema, é só voltar para o login e clicar em <strong>esqueci minha senha</strong>.</>
      : 'Poxa, esse link já não funciona mais... Entre em contato com o administrador do marketplace e ele resolverá rapidinho!'

    return (
      <Fragment>
        <AuthStatusMessage
          type='error'
          path='/login'
          labelButton='Voltar para o login'
          title={title}
          message={message}
        />
      </Fragment>
    )
  }
}
