import React from 'react'
import { useSelector } from 'react-redux'

import {
  CardStyled,
  ContainerEmptyState } from '../styles'
import Label from 'src/UIComponents/Label/Label'
import { darkGray, darkerGray, smallText } from 'src/Styles/settings/Constants'

import BarChart from 'src/UIComponents/Charts/BarChart'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import ErrorStateCard from 'src/Components/ErrorStateCard/ErrorStateCard'

import ProgressiveChartIcon from 'src/Assets/icons/others/progressive-chart.svg'
import ReloadIcon from 'src/Assets/icons/actions/reload.svg'

import { allTogether } from 'src/Actions/kpi'

const MarkupProgression = (props) => {
  const { historic, color, request, lastCommission, allRequests, statusCode } = useSelector(
    ({
      custom,
      kpi: {
        commissions: { succeeded },
        allTogetherKPIs: { allRequests }
      },
      commissions
    }) => ({
      ...succeeded,
      color: custom.color,
      lastCommission: commissions.lastCommission,
      statusCode: succeeded.error && succeeded.error.status,
      allRequests
    })
  )

  // verifica se existe algum mes com dados dentro do objeto para validar a estrutura do gráfico
  const commissionsFiltered =
    historic && historic.filter((item) => item.name !== null)
  const hasCommissions = Boolean(
    commissionsFiltered && commissionsFiltered.length > 0
  )
  const hasLastCommissions = Boolean(lastCommission)
  const isLoading = Boolean(allRequests || request)
  const description =
    hasLastCommissions && !hasCommissions
      ? 'Sem progressão de Markup nos últimos 12 meses.'
      : 'Ainda sem progressão de Markup.'
  return (
    <>
      <CardStyled notData={!isLoading && !hasCommissions} {...props}>
        <Label bold uppercase color={darkGray} fontSize={smallText}>
          Progressão de Markups
        </Label>
        {!isLoading && !hasCommissions ? (
          <ContainerEmptyState>
            {statusCode === 404 && (
              <EmptyState
                iconSrc={ProgressiveChartIcon}
                description={description}
                iconColor={darkerGray}
                iconSize='small'
              />
            )}
            {statusCode !== 404 && (
              <ErrorStateCard
                iconSrc={ReloadIcon}
                iconColor={darkerGray}
                color={darkerGray}
                iconSize='small'
                action={allTogether}
              />
            )}
          </ContainerEmptyState>
        ) : (
          <BarChart
            color={color}
            width='100%'
            layout='horizontal'
            height={252}
            data={historic}
            itemsType='commission'
            isLoading={isLoading}
          />
        )}
      </CardStyled>
    </>
  )
}

export default MarkupProgression
