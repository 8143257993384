import React, { createContext, useState } from 'react'
import * as PropTypes from 'prop-types'

const CreateTransferContext = createContext()

const CreateTransferContextProvider = ({ children }) => {
  const [state, setState] = useState({
    receiver: null
  })
  return (
    <CreateTransferContext.Provider
      value={{
        state,
        setState
      }}
    >
      { children }
    </CreateTransferContext.Provider>
  )
}

CreateTransferContextProvider.propTypes = {
  children: PropTypes.any
}

export { CreateTransferContextProvider }
export default CreateTransferContext
