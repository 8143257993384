/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react'
import { darkGray, normalText } from 'src/Styles/settings/Constants'
import { getSellers, resetSellers } from 'src/Actions/sellers'
import { useDispatch, useSelector } from 'react-redux'

import Can from 'src/Helpers/Permission/Can'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'
import PropTypes from 'prop-types'
import { SellerFilter } from 'src/Containers/Seller/Filter'
import Table from 'src/Components/Table/NewTable'
import Title from 'src/UIComponents/Title/Title'
import { clamp } from 'lodash'
import moment from 'moment'
import { statusLabels } from 'src/Constants/StatusLabels'
import { useQuery } from 'src/Utils/Hooks'

const SellerTable = ({ columns, onItemClick }) => {
  const dispatch = useDispatch()

  const {
    sellersRequesting: loading,
    sellersError: error,
    sellers,
    sellersTotalPages: totalPages,
    sellersPageSize: pageSize,
    sellersTotalItems: totalItems
  } = useSelector(({ sellers }) => sellers)

  const query = useQuery()
  const [filters, setFilters] = useState(query)
  const [search, setSearch] = useState()
  const [currentPage, setCurrentPage] = useState(
    filters ? (filters.offset || 0) / pageSize : 0
  )
  const [resultsPerPage, setResultsPerPage] = useState(pageSize)
  const [sort, setSort] = useState('created_at:desc')

  useEffect(() => {
    dispatch(getSellers(filters))

    return () => {
      dispatch(resetSellers())
    }
  }, [])

  useEffect(() => {
    if (loading) return

    let key = 'name'
    if (parseInt(search)) {
      if (search.length === 11) {
        key = 'taxpayer_id'
      } else if (search.length === 14) {
        key = 'ein'
      }
    }

    dispatch(
      getSellers({
        ...filters,
        limit: resultsPerPage,
        offset: currentPage * resultsPerPage,
        [key]: search,
        sort
      })
    )
  }, [filters, resultsPerPage, currentPage, search, sort])

  const handleFilterSubmit = (newFilters = {}) => {
    handleClearSearchInput()
    setFilters(newFilters)
  }

  const handleClearAllFilters = () => {
    setCurrentPage(0)
    setFilters({})
  }

  const handleSearchSubmit = (value) => {
    handleClearAllFilters()
    setSearch(value.trim().replace(/[.,\-,/]/g, ''))
  }

  const handleClearSearchInput = () => {
    setCurrentPage(0)
    setSearch()
  }

  const handleResultsPerPageChange = (value) => {
    setCurrentPage(0)
    setResultsPerPage(value)
  }

  const handlePageChange = (page) => {
    const newPage = clamp(page, 0, totalPages)
    setCurrentPage(newPage)
  }

  const handleSortChange = (sorted) => {
    setCurrentPage(0)
    const sort =
      sorted.length > 0
        ? `${sorted[0].id}:${sorted[0].desc ? 'desc' : 'asc'}`
        : 'created_at:desc'
    setSort(sort)
  }

  const emptyStateMsg = search
    ? 'Não encontramos este estabelecimento'
    : `Nenhum estabelecimento encontrado de\n acordo com os filtros aplicados`
  const emptyStateDescription =
    search && 'Verifique se o nome ou número digitados estão corretos'
  const emptyStateIconPath = search
    ? '/assets/icons/actions/search.svg'
    : '/assets/icons/others/enterprise.svg'

  return (
    <>
      <Grid.Row>
        <Grid.Col cols={14}>
          <Title bar small>
            <strong>Lista</strong> de Estabelecimentos
          </Title>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={16}>
          <SellerFilter
            search={search}
            values={filters}
            searchPlaceholder={'Buscar pelo CPF/CNPJ ou Nome'}
            onSearchSubmit={handleSearchSubmit}
            onSearchClear={handleClearSearchInput}
            onFiltersApply={handleFilterSubmit}
            onFiltersClear={handleClearAllFilters}
            noDateRange
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row auto id='table-history'>
        <Can I='read' a='SellerDetail' passThrough>
          {(canReadDetail) => (
            <Table
              loading={loading}
              tableColumns={columns}
              tableData={sellers}
              totalItems={totalItems}
              totalPages={totalPages}
              pageSize={pageSize}
              error={error}
              emptyState={{
                message: emptyStateMsg,
                description: emptyStateDescription,
                icon: emptyStateIconPath
              }}
              iconComponent={canReadDetail ? undefined : false}
              currentPage={currentPage}
              manual
              showPageSizeOptions
              onResultsPerPageChange={handleResultsPerPageChange}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              onItemClick={onItemClick}
              defaultSortBy={[
                {
                  id: 'created_at',
                  desc: true
                }
              ]}
              data-tracking='Sellers'
            />
          )}
        </Can>
      </Grid.Row>
    </>
  )
}

SellerTable.defaultProps = {
  filters: {},
  columns: [
    {
      Header: 'Data de Criação',
      accessor: 'created_at',
      width: '10%',
      Cell: ({ cell }) => (
        <Label fontSize={normalText} color={darkGray}>
          {moment(cell.value).format('DD/MM/YYYY')}
        </Label>
      ),
      sortable: true,
      maxWidth: 200
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: '15%',
      Cell: ({ cell }) => (
        <Label color={statusLabels[cell.value].color} bold uppercase>
          {statusLabels[cell.value].label}
        </Label>
      ),
      sortable: false,
      maxWidth: 200
    },
    {
      Header: 'Estabelecimento',
      width: '20%',
      Cell: ({ row }) => (
        <div>
          {row.original.type === 'business' ? (
            <Label bold color={darkGray} fontSize={normalText} overflow>
              {row.original.statement_descriptor}
            </Label>
          ) : (
            <Label color={darkGray} fontSize={normalText} overflow>
              {`${row.original.first_name} ${row.original.last_name}`}
            </Label>
          )}
          {row.original.type === 'business' && (
            <div>
              <Label overflow color={darkGray}>
                {row.original.business_name}
              </Label>
            </div>
          )}
        </div>
      ),
      sortable: false
    },
    {
      Header: 'CPF/CNPJ',
      Cell: ({ row }) => {
        let documentLabel = null
        if (row.original.type === 'business') {
          documentLabel =
            row.original.ein && `CNPJ ${row.original.ein?.toCnpj()}`
        } else {
          documentLabel =
            row.original.taxpayer_id &&
            `CPF ${row.original.taxpayer_id.toCPF()}`
        }
        return (
          <LabelCopyToClipboard color={darkGray} text={documentLabel}>
            {documentLabel}
          </LabelCopyToClipboard>
        )
      },
      sortable: false,
      width: '30%'
    },
    {
      Header: 'E-mail',
      width: '20%',
      Cell: ({ row }) => (
        <Label color={darkGray} fontSize={normalText} overflow>
          {row.original.type === 'business'
            ? row.original.business_email || '-'
            : row.original.email || '-'}
        </Label>
      ),
      sortable: false
    }
  ],
  onItemClick: () => {
    /* noop */
  }
}

SellerTable.propTypes = {
  filters: PropTypes.object,
  columns: PropTypes.array,
  onItemClick: PropTypes.func
}

export { SellerTable as Table }
