import React from 'react'
import Can from 'src/Helpers/Permission/Can'
import MainTemplate, { Container, Column } from 'src/UIComponents/Template'
import Grid from 'src/UIComponents/Grid/Grid'

import { Title as Helmet } from 'src/Helpers/WhiteLabel'
import { Table, Metrics } from 'src/Containers/Seller'
import { useHistory } from 'react-router-dom'
import createToast from 'src/Utils/createToast'

const Seller = () => {
  const history = useHistory()

  const handleShowSellerDetail = (item) => {
    if (item?.status === 'deleted') {
      createToast(
        'error',
        'Não é possível acessar um estabelecimento deletado'
      )
    } else {
      history.push(`/estabelecimento/${item.id}`)
    }
  }

  return (
    <MainTemplate>
      <Helmet>Estabelecimentos</Helmet>
      <Metrics />
      <Can do='read' on='Sellers'>
        <Can not do='read' on='KPI'>
          <br />
          <br />
        </Can>
        <Container>
          <Column>
            <Grid noPadding>
              <Table onItemClick={handleShowSellerDetail} />
            </Grid>
          </Column>
        </Container>
      </Can>
    </MainTemplate>
  )
}

export default Seller
