import React from 'react'
import PropTypes from 'prop-types'
import DateInput from 'src/UIComponents/Date/DateInput'
import { RangeSelector } from 'src/UIComponents/Range'
import Filter from 'src/UIComponents/Filter/Filter'

const SaleFilter = props => <Filter defaultSearch={props.search} {...props} />

SaleFilter.propTypes = {
  search: PropTypes.string,
  filters: PropTypes.array
}

SaleFilter.defaultProps = {
  filters: [
    {
      placeholder: 'Data',
      component: DateInput,
      accessor: 'date_range'
    },
    {
      placeholder: 'Status',
      accessor: 'status',
      isMultiSelect: true,
      transformer: {
        format: (value) => {
          return typeof value === 'string' ? (value.length > 0 ? value.split(',') : undefined) : value
        },
        parse: (value) => {
          if (value && Array.isArray([...value])) {
            const selectedStatuses = [...value]
            const values = selectedStatuses.map(({ value }) => value)
            return values.join(',')
          }
          return value
        }
      },
      options: [
        { label: 'Aprovada', value: 'succeeded' },
        { label: 'Cancelada', value: 'canceled' },
        { label: 'Chargeback', value: 'charged_back' },
        { label: 'Em disputa', value: 'dispute' },
        { label: 'Estornada', value: 'refunded' },
        { label: 'Expirada', value: 'expired' },
        { label: 'Falhada', value: 'failed' },
        { label: 'Nova', value: 'new' },
        { label: 'Pendente', value: 'pending' },
        { label: 'Pré-autorizada', value: 'pre_authorized' },
        { label: 'Revertida', value: 'reversed' }
      ]
    },
    {
      placeholder: 'Modo de Captura',
      accessor: 'entry_mode',
      isMultiSelect: true,
      transformer: {
        format: (value) => {
          return typeof value === 'string' ? (value.length > 0 ? value.split(',') : undefined) : value
        },
        parse: (value) => {
          if (value && Array.isArray([...value])) {
            const selectedPaymentTypes = [...value]
            const values = selectedPaymentTypes.map(({ value }) => value)
            return values.join(',')
          }
          return value
        }
      },
      options: [
        { label: 'Online', value: 'manually_keyed|credit_manually_keyed|debit_manually_keyed|pending|data_only' }, 
        { label: 'Presencial', value: 'chip|contactless_chip|contactless_tap' }
      ]
    },
    {
      placeholder: 'Tipo',
      accessor: 'payment_type',
      isMultiSelect: true,
      transformer: {
        format: (value) => {
          return typeof value === 'string' ? (value.length > 0 ? value.split(',') : undefined) : value
        },
        parse: (value) => {
          if (value && Array.isArray([...value])) {
            const selectedPaymentTypes = [...value]
            const values = selectedPaymentTypes.map(({ value }) => value)
            return values.join(',')
          }
          return value
        }
      },
      options: [
        { label: 'Boleto', value: 'boleto' },
        { label: 'Boleto com pix', value: 'bolepix' },
        { label: 'Crédito', value: 'credit' },
        { label: 'Débito', value: 'debit' },
        { label: 'Nupay', value: 'nupay' },
        { label: 'Pix', value: 'pix' },
        { label: 'Voucher', value: 'voucher' },
        { label: 'Wallet', value: 'wallet' }
      ]
    },
    {
      placeholder: 'Valor da venda',
      component: RangeSelector,
      accessor: 'amount_range'
    }
  ]
}

export {
  SaleFilter
}
