import React from 'react'

import Card from 'src/UIComponents/Card/Card'
import TableLastSales from 'src/Containers/Home/TableLastSales'

const LastSales = (props) => (
  <Card {...props}>
    <TableLastSales />
  </Card>
)

export default LastSales
