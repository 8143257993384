import React, { useState } from 'react'

import MainTemplate, { Column } from 'src/UIComponents/Template'
import { DashboardAnalytics, DashboardAnalyticsInfoResume } from './styles'
import Can from 'src/Helpers/Permission/Can'
import { Title as Helmet } from 'src/Helpers/WhiteLabel'
import { Metrics } from './Metrics'

import MarkupProgression from './kpis/MarkupProgression'
import SaleProgression from './kpis/SaleProgression'
import HistoryByType from './kpis/HistoryByType'
import FailedCapturing from './kpis/FailedCapturing'
import LastSales from './kpis/LastSales'
import TopHitsSellersByAmount from './kpis/TopHitsSellersByAmount'
import CardBrand from './kpis/CardBrand'

import styled from 'styled-components'
import { useSelector } from 'react-redux'
import CreateSlugDisclaimerModal from '../../UIComponents/CreateSlugDisclaimerModal/CreateSlugDisclaimerModal'

const Home = () => {
  const slug = window.location.hostname.split('.')[0]

  const { seller, hasSlug, customError } = useSelector(
    ({ context, custom }) => ({
      seller: context.seller,
      hasSlug: Boolean(slug),
      customError: custom.error
    })
  )

  const [showCreateSlugModal, setShowCreateSlugModal] = useState(
    !hasSlug && !sessionStorage.getItem('hideCreateSlug') && !customError
  )

  return (
    <MainTemplate>
      {showCreateSlugModal && (
        <CreateSlugDisclaimerModal
          handleOnClose={() => setShowCreateSlugModal(false)}
        />
      )}
      <Helmet>Home</Helmet>
      <Can do='read' on='KPI'>
        <Metrics
          customInfoResume={
            <DashboardAnalyticsInfoResume>
              {!seller && <MarkupProgression />}
              <SaleProgression />
              <HistoryByType />
              <FailedCapturing />
            </DashboardAnalyticsInfoResume>
          }
        />
        <StyledContainer>
          <Column>
            <DashboardAnalytics>
              <LastSales className='span2' />
              {!seller && <TopHitsSellersByAmount />}
              <CardBrand />
            </DashboardAnalytics>
          </Column>
        </StyledContainer>
      </Can>
    </MainTemplate>
  )
}

export default Home
const StyledContainer = styled.div`
  &:last-of-type {
    margin-bottom: 4rem;
  }
`
