import React from 'react'

import Can from 'src/Helpers/Permission/Can'
import { Title as Helmet } from 'src/Helpers/WhiteLabel'

import { Table, Metrics } from 'src/Containers/Sale'
import MainTemplate, { Container, Column } from 'src/UIComponents/Template'
import Grid from 'src/UIComponents/Grid/Grid'

const Sale = () => {
  return (
    <MainTemplate>
      <Helmet>Vendas</Helmet>
      <Can do='read' on='KPI'>
        <Metrics />
      </Can>
      <Can do='read' on='Transaction'>
        <Container>
          <Column>
            <Grid noPadding>
              <Table />
            </Grid>
          </Column>
        </Container>
      </Can>
    </MainTemplate>
  )
}

export default Sale
