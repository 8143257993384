export default [[
  {
    title: 'Dados Cadastrais',
    to: '/configuracoes/cadastro',
    id: 'cadastro'
  },
  {
    title: 'Meios de Recebimento',
    to: '/configuracoes/meios-recebimento',
    id: 'meiosRecebimento'
  },
  {
    title: 'Usuários',
    to: '/configuracoes/usuarios',
    id: 'usuarios'
  },
  {
    title: 'Webhooks',
    to: '/configuracoes/webhooks',
    id: 'webhooks'
  },
  {
    title: 'Planos',
    to: '/configuracoes/planos',
    id: 'planos'
  },
  {
    title: 'Personalização',
    to: '/configuracoes/personalizacao',
    id: 'personalizacao'
  }
],
[
  {
    title: 'Recursos do Minha Conta',
    to: '/configuracoes/recursos-minha-conta',
    id: 'recursosMinhaConta'
  }
]
]
