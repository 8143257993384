import React, { useEffect } from 'react'
import { InnerHeader } from 'src/UIComponents/Template'
import HeaderInfo from 'src/Components/HeaderInfo/HeaderInfo'
import { getCardTitles, getKPIsSaleInfo } from 'src/Utils/KPIsInfo'
import {
  getTransfersFailed,
  getTransfersSucceededDetail,
  getTransfersToTheMarketplace
} from 'src/Actions/kpi'
import { matchPath, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const Metrics = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    seller,
    transfers,
    period,
    marketplace
  } = useSelector(({ kpi, context }) => ({
    ...kpi,
    ...context
  }))

  useEffect(() => {
    dispatch(getTransfersToTheMarketplace())
    dispatch(getTransfersFailed())
    dispatch(getTransfersSucceededDetail())
  }, [period, seller, marketplace])

  const mountInfoResume = () => {
    const kpisKeys = Object.keys(transfers).filter(key => seller?.id ? key !== 'toTheMarketplace' : true)

    const infoResume = new Map(kpisKeys.map((key) => {
      const { request, error, amount } = transfers[key]

      const info = getKPIsSaleInfo({
        type: 'transfer', key, amount, error, request
      })

      if (error && error.status !== 404) {
        return [key, {
          icon: '/assets/icons/actions/reload.svg',
          title: 'Desculpe tivemos um problema',
          info: 'Clique aqui para recarregar.',
          isEmpty: true,
          onClick: () => {
            if (key === 'succeeded') return dispatch(getTransfersSucceededDetail())
            if (key === 'failed') return dispatch(getTransfersFailed())
            if (key === 'toTheMarketplace') return dispatch(getTransfersToTheMarketplace())
          }
        }]
      }

      const isEmpty = (error && error.status === 404) || amount === 0

      return [key, {
        label: getCardTitles('transfer', key),
        info,
        isEmpty,
        onClick: () => {
          const { pathname, search } = document.location
          if (!matchPath(`${pathname}${search}`, {
            path: `transferencias/kpi?type=${key}`,
            exact: true,
            strict: false
          })) {
            history.push(`transferencias/kpi?type=${key}`)
          }
        }
      }]
    }))

    return Array.from(infoResume.values())
  }

  return (
    <InnerHeader>
      <HeaderInfo infoResume={mountInfoResume()} title='transferências' showSellerSelector />
    </InnerHeader>
  )
}

export { Metrics }
