/* eslint react/prop-types: 0 */
import {
  darkerGray,
  smallText,
  smallerText
} from 'src/Styles/settings/Constants'

import { Beacon } from 'src/Components/OnboardingHints/Beacon'
import Button from 'src/UIComponents/Button/ButtonDefault'
import CardBrandDisplay from 'src/UIComponents/CardBrandDisplay/CardBrandDisplay'
import Label from 'src/UIComponents/Label/Label'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'
import PropTypes from 'prop-types'
import React from 'react'
import Table from 'src/Components/Table/NewTable'
import Title from 'src/UIComponents/Title/Title'
import { TransactionStatus } from 'src/Constants'
import styled from 'styled-components'
import { toMoney } from 'src/Utils/Utils'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const TableLastSales = ({ columns }) => {
  const history = useHistory()
  const {
    transactionsRequesting: loading,
    transactions,
    transactionsError: error
  } = useSelector(({ transactions }) => ({
    transactionsRequesting: transactions.transactionsRequesting,
    transactionsError: transactions.transactionsError,
    transactions: transactions.transactions.slice(0, 3)
  }))

  return (
    <Container>
      <WrapperButtons>
        <Title bar small>
          <strong>Últimas</strong> vendas
        </Title>
        <ButtonSale
          link
          widthAuto
          upperCase
          onClick={() => history.push('vendas')}
          data-test='home-btn-last-sales'
          data-tracking='Home > Ver todas as vendas'
        >
          ver todas as vendas
        </ButtonSale>
      </WrapperButtons>
      <Table
        loading={loading}
        tableColumns={columns}
        tableData={transactions}
        error={error}
        emptyState={{
          description: 'Não existem vendas até o momento.',
          icon: '/assets/icons/others/sales.svg'
        }}
        iconComponent={null}
        showPagination={false}
      />
    </Container>
  )
}

TableLastSales.defaultProps = {
  columns: [
    {
      Header: 'Data',
      accessor: 'date',
      Cell: ({ cell }) => {
        if (!cell.value) {
          return
        }
        return (
          <div style={{ fontSize: smallText }}>
            {cell.value.day} <br /> {cell.value.hour}{' '}
          </div>
        )
      },
      width: '15%'
    },
    {
      Header: 'ID da transação',
      accessor: 'id',
      Cell: ({ row }) => {
        const { original } = row
        return (
          <LabelCopyToClipboard
            color={darkerGray}
            fontSize={smallText}
            text={original.id}
          >
            {original.id}
          </LabelCopyToClipboard>
        )
      },
      width: '25%'
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: '25%',
      Cell: ({ row, cell }) => {
        const { original, index } = row
        const name = () => {
          if (original.individual) {
            return original.individual.first_name &&
              original.individual.last_name
              ? `${original.individual.first_name} ${original.individual.last_name}`
              : original.individual.first_name
                ? original.individual.first_name
                : original.individual.last_name
                  ? original.individual.last_name
                  : ''
          }
          if (original.business) {
            return original.business.business_name || ''
          }
          return ''
        }

        return (
          <>
            {TransactionStatus[cell.value] && (
              <Label
                color={TransactionStatus[cell.value].color}
                bold
                uppercase
                fontSize={smallerText}
              >
                {TransactionStatus[cell.value].label}
              </Label>
            )}
            <div>
              <Label bold overflow color={darkerGray} fontSize={smallText}>
                {name()}
              </Label>
              {index === 0 && <Beacon hint='einCopy' />}
              <LabelCopyToClipboard
                color={darkerGray}
                fontSize={smallText}
                text={
                  original.business && original.business.ein
                    ? original.business.ein?.toCnpj()
                    : original.individual && original.individual.taxpayer_id
                      ? original.individual.taxpayer_id.toCPF()
                      : ''
                }
              >
                {original.business && original.business.ein
                  ? `CNPJ ${original.business.ein?.toCnpj()}`
                  : original.individual && original.individual.taxpayer_id
                    ? `CPF ${original.individual.taxpayer_id.toCPF()}`
                    : ''}
              </LabelCopyToClipboard>
            </div>
          </>
        )
      }
    },
    {
      Header: 'Tipo',
      accessor: 'payment_method',
      width: '20%',
      Cell: ({ row, cell }) => (
        <div>
          <CardBrandDisplay
            src={cell.value ? cell.value.card_brand : undefined}
            paymentType={row.original.payment_type}
            installmentPlan={row.original.installment_plan}
            fontText={smallText}
            displayColumn
          />
        </div>
      )
    },
    {
      Header: 'Valor da Venda',
      accessor: 'amount',
      width: '15%',
      Cell: ({ row }) => {
        const { original } = row
        return (
          <Label color={darkerGray} fontSize={smallText}>
            {toMoney(original.amount)}
          </Label>
        )
      }
    }
  ]
}

TableLastSales.propTypes = {
  columns: PropTypes.array
}

const Container = styled.div`
  width: 100%;
`

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ButtonSale = styled(Button)`
  font-size: ${smallText};
  cursor: pointer;
`

export default TableLastSales
