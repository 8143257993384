import React from 'react'
import { useSelector } from 'react-redux'

import {
  ContainerEmptyState } from '../styles'
import Label from 'src/UIComponents/Label/Label'
import { darkGray, darkerGray, smallText } from 'src/Styles/settings/Constants'

import Card from 'src/UIComponents/Card/Card'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import ErrorStateCard from 'src/Components/ErrorStateCard/ErrorStateCard'

import ReloadIcon from 'src/Assets/icons/actions/reload.svg'
import Sales from 'src/Assets/icons/others/sales.svg'

import TransactionsByCardBrand from 'src/UIComponents/KPIDetails/TransactionsByCardBrand'
import { allTogether } from 'src/Actions/kpi'

const CardBrand = (props) => {
  const { topHits, request, allRequests, statusCode } = useSelector(
    ({
      custom,
      kpi: {
        period,
        allTogetherKPIs: { allRequests },
        transactions: { succeeded }
      }
    }) => ({
      ...succeeded,
      period,
      color: custom.color,
      allRequests,
      statusCode: succeeded.error && succeeded.error.status
    })
  )

  const isLoading = Boolean(allRequests || request)
  return (
    <Card {...props}>
      <Label
        uppercase
        bold
        color={darkGray}
        fontSize={smallText}
        textAlign='center'
      >
        {'maior volume de '}
        <Label uppercase bold fontSize={smallText}>
          vendas por bandeiras
        </Label>
      </Label>
      {!isLoading && (!topHits || (topHits && topHits.length === 0)) ? (
        <ContainerEmptyState>
          {statusCode === 404 && (
            <EmptyState
              iconSrc={Sales}
              iconColor={darkerGray}
              iconSize='small'
              description='Não existem vendas por <br /> bandeira até o momento.'
            />
          )}
          {statusCode !== 404 && (
            <ErrorStateCard
              iconSrc={ReloadIcon}
              iconColor={darkerGray}
              color={darkerGray}
              iconSize='small'
              action={allTogether}
            />
          )}
        </ContainerEmptyState>
      ) : (
        <TransactionsByCardBrand items={topHits} isLoading={isLoading} />
      )}
    </Card>
  )
}

export default CardBrand
