import React from 'react'
import Filter from 'src/UIComponents/Filter/Filter'
import PropTypes from 'prop-types'

const SellerFilter = props => <Filter defaultSearch={props.search} {...props} />

SellerFilter.propTypes = {
  search: PropTypes.string,
  filters: PropTypes.array
}

SellerFilter.defaultProps = {
  filters: [
    {
      placeholder: 'Status',
      accessor: 'status',
      isMultiSelect: true,
      transformer: {
        format: (value) => {
          return typeof value === 'string' ? (value.length > 0 ? value.split(',') : undefined) : value
        },
        parse: (value) => {
          if (value && Array.isArray([...value])) {
            const selectedStatuses = [...value]
            const values = selectedStatuses.map(({ value }) => value)
            return values.join(',')
          }
          return value
        }
      },
      options: [
        { label: 'Habilitado', value: 'enabled' },
        { label: 'Ativo', value: 'active' },
        { label: 'Pendente', value: 'pending' },
        { label: 'Inativo', value: 'disabled' },
        { label: 'Negado', value: 'denied' },
        { label: 'Deletado', value: 'deleted' }
      ]
    }
  ]
}

export {
  SellerFilter
}
