/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react'
import { darkerGray, normalText } from 'src/Styles/settings/Constants'
import { useDispatch, useSelector } from 'react-redux'

import Can from 'src/Helpers/Permission/Can'
import CardBrandDisplay from 'src/UIComponents/CardBrandDisplay/CardBrandDisplay'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'
import PropTypes from 'prop-types'
import { ReportButton } from 'src/Components/Report'
import { SaleFilter } from 'src/Containers/Sale/Filter'
import Table from 'src/Components/Table/NewTable'
import Title from 'src/UIComponents/Title/Title'
import { TransactionStatus } from 'src/Constants'
import { clamp } from 'lodash'
import { getTransactions } from 'src/Actions/transactions'
import { toMoney } from 'src/Utils/Utils'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'src/Utils/Hooks'
import { getEntryMode } from '../../Constants/EntryModesDict'

const SaleTable = ({ columns }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    transactionsRequesting: loading,
    transactions,
    transactionsTotalItems: totalItems,
    transactionsTotalPages: totalPages,
    transactionsPageSize: pageSize,
    transactionsError: error
  } = useSelector(({ transactions }) => transactions)

  const query = useQuery()
  const [filters, setFilters] = useState(query)
  const [search, setSearch] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const [resultsPerPage, setResultsPerPage] = useState(pageSize || 100)
  const [sort, setSort] = useState('created_at:desc')

  useEffect(() => {
    if (loading) return

    dispatch(
      getTransactions({
        ...filters,
        limit: resultsPerPage,
        offset: currentPage * resultsPerPage,
        search_id: search,
        sort
      })
    )
  }, [filters, resultsPerPage, currentPage, search, sort])

  const handleItemClick = (item) => {
    history.push(`vendas/detalhe/${item.id}`)
  }

  const handleFilterSubmit = (newFilters = {}) => {
    handleClearSearchInput()
    setFilters(newFilters)
  }

  const handleClearAllFilters = () => {
    setCurrentPage(0)
    setFilters({})
  }

  const handleSearchSubmit = (value) => {
    handleClearAllFilters()
    setSearch(value)
  }

  const handleClearSearchInput = () => {
    setCurrentPage(0)
    setSearch()
  }

  const handleResultsPerPageChange = (value) => {
    setCurrentPage(0)
    setResultsPerPage(value)
  }

  const handlePageChange = (page) => {
    const newPage = clamp(page, 0, totalPages)
    setCurrentPage(newPage)
  }

  const handleSortChange = (sorted) => {
    setCurrentPage(0)
    const sort =
      sorted.length > 0
        ? `${sorted[0].id}:${sorted[0].desc ? 'desc' : 'asc'}`
        : 'created_at:desc'
    setSort(sort)
  }

  return (
    <>
      <Grid.Row>
        <Grid.Col cols={10}>
          <Title bar small>
            <strong>Histórico</strong> de vendas
          </Title>
        </Grid.Col>
        <Can do='create' on='ExportTransaction'>
          <Grid.Col cols={6}>
            <ReportButton
              type='transactions'
              filters={{
                payment_type: [
                  'credit',
                  'debit',
                  'boleto',
                  'pix',
                  'voucher',
                  'wallet',
                  'nupay',
                  'bolepix'
                ],
                ...filters
              }}
              results={transactions.length}
            />
          </Grid.Col>
        </Can>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={16}>
          <SaleFilter
            search={search}
            values={filters}
            searchPlaceholder='Buscar pelo ID da venda ou ID de referência'
            onSearchSubmit={handleSearchSubmit}
            onSearchClear={handleClearSearchInput}
            onFiltersApply={handleFilterSubmit}
            onFiltersClear={handleClearAllFilters}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row auto id='table-history'>
        <Table
          loading={loading}
          tableColumns={columns}
          tableData={transactions}
          totalItems={totalItems}
          totalPages={totalPages}
          pageSize={pageSize}
          error={error}
          emptyState={{
            message: search
              ? 'Não encontramos esta venda'
              : ' Nenhuma venda encontrada de acordo\n com os filtros aplicados',
            description:
              search && 'Verifique se o ID que você buscou está correto',
            icon: search
              ? '/assets/icons/actions/search.svg'
              : '/assets/icons/others/sales.svg'
          }}
          manual
          currentPage={currentPage}
          showPageSizeOptions
          onResultsPerPageChange={handleResultsPerPageChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onItemClick={handleItemClick}
          dataTracking='Transaction'
          defaultSortBy={[
            {
              id: 'date',
              desc: true
            }
          ]}
        />
      </Grid.Row>
    </>
  )
}

SaleTable.defaultProps = {
  filters: {},
  columns: [
    {
      Header: 'Data',
      accessor: 'date',
      Cell: ({ cell }) => {
        if (!cell.value) {
          return
        }
        return (
          <div>
            {cell.value.day} <br /> {cell.value.hour}{' '}
          </div>
        )
      },
      sortable: true,
      width: '10%'
    },
    {
      Header: 'ID da transação',
      accessor: 'id',
      Cell: ({ row }) => {
        const { original } = row
        return (
          <LabelCopyToClipboard color={darkerGray} text={original.id}>
            {original.id}
          </LabelCopyToClipboard>
        )
      },
      width: '25%'
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ cell }) =>
        TransactionStatus[cell.value] ? (
          <Label
            color={TransactionStatus[cell.value].color}
            bold
            uppercase
            overflow
          >
            {TransactionStatus[cell.value].label}
          </Label>
        ) : (
          cell.value
        ),
      width: '10%'
    },
    {
      Header: 'Estabelecimento',
      Cell: ({ row }) => {
        const { original } = row
        const name = () => {
          if (original.individual) {
            return original.individual.first_name &&
              original.individual.last_name
              ? `${original.individual.first_name} ${original.individual.last_name}`
              : original.individual.first_name
                ? original.individual.first_name
                : original.individual.last_name
                  ? original.individual.last_name
                  : ''
          }
          if (original.business) {
            return original.business.business_name || ''
          }
          return ''
        }
        return (
          <div>
            <Label bold overflow color={darkerGray} fontSize={normalText}>
              {name()}
            </Label>
            <LabelCopyToClipboard
              color={darkerGray}
              text={
                original.business && original.business.ein
                  ? original.business.ein?.toCnpj()
                  : original.individual && original.individual.taxpayer_id
                    ? original.individual.taxpayer_id.toCPF()
                    : ''
              }
            >
              {original.business && original.business.ein
                ? `CNPJ ${original.business.ein?.toCnpj()}`
                : original.individual && original.individual.taxpayer_id
                  ? `CPF ${original.individual.taxpayer_id.toCPF()}`
                  : ''}
            </LabelCopyToClipboard>
          </div>
        )
      },
      width: '20%'
    },
    {
      Header: 'Modo de captura',
      acessor: 'entry_mode',
      Cell: ({ row }) => {
        const { original } = row
        const mode = () => {
          if (
            original.payment_type === 'debit' ||
            original.payment_type === 'credit' ||
            original.payment_type === 'nupay'
          ) {
            return getEntryMode(original.point_of_sale.entry_mode)
          }
          return ''
        }

        return (
          <div>
            <LabelCopyToClipboard color={darkerGray}>
              {mode()}
            </LabelCopyToClipboard>
          </div>
        )
      },
      width: '15%'
    },
    {
      Header: 'Tipo',
      accessor: 'payment_method',
      Cell: ({ cell, row }) => (
        <div>
          <CardBrandDisplay
            src={cell.value ? cell.value.card_brand : undefined}
            paymentType={row.original.payment_type}
            installmentPlan={row.original.installment_plan}
          />
        </div>
      ),
      width: '15%'
    },
    {
      Header: 'Valor da venda',
      accessor: 'amount',
      Cell: ({ cell }) => toMoney(cell.value),
      width: '10%'
    }
  ],
  onItemClick: () => {
    /* noop */
  }
}

SaleTable.propTypes = {
  filters: PropTypes.object,
  columns: PropTypes.array,
  onItemClick: PropTypes.func
}

export { SaleTable as Table }
