import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as PropTypes from 'prop-types'

import { getBanks } from 'src/Actions/banks'
import { Title as Helmet } from 'src/Helpers/WhiteLabel'

import { Table } from 'src/Containers/Transfer/Table'
import { Metrics } from 'src/Containers/Transfer/Metrics'
import MainTemplate, { Container, Column } from 'src/UIComponents/Template'
import Grid from 'src/UIComponents/Grid/Grid'

const Transfer = ({ history }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBanks())
  }, [])

  const handleShowTransferDetail = (item) => {
    history.push(`transferencias/detalhe/${item.id}`)
  }

  return (
    <MainTemplate>
      <Helmet>Transferências</Helmet>
      <Metrics />
      <Container>
        <Column>
          <Grid noPadding>
            <Table onItemClick={handleShowTransferDetail} />
          </Grid>
        </Column>
      </Container>
    </MainTemplate>
  )
}

Transfer.propTypes = {
  history: PropTypes.object.isRequired
}

export default Transfer
