import React from 'react'
import { Beacon } from '../../Components/OnboardingHints/Beacon'

export default [
  {
    id: 'home',
    title: 'Home',
    to: '/home',
    permissions: {
      hide: ['full_access_configuration']
    }
  },
  {
    id: 'sales',
    title: 'Vendas',
    to: '/vendas',
    hint: <Beacon hint='menu-vendas' wrapperProps={{ top: '25px', right: '40px' }} />,
    permissions: {
      hide: ['full_access_configuration']
    }
  },
  {
    id: 'transfers',
    title: 'Transferências',
    to: '/transferencias',
    hint: <Beacon hint='menu-transferencias' wrapperProps={{ top: '25px', right: '70px' }} />,
    permissions: {
      hide: ['full_access_configuration']
    }
  },
  // {
  //   title: 'Relatórios',
  //   to: '/relatorios'
  // },
  {
    id: 'sellers',
    title: 'Estabelecimentos',
    to: '/estabelecimentos',
    hint: <Beacon hint='menu-estabelecimentos' wrapperProps={{ top: '25px', right: '90px' }} />
  }
  // {
  //   title: 'Planos de venda',
  //   to: '/planos'
  // }
]
