import React from 'react'
import { useSelector } from 'react-redux'

import {
  CardStyled,
  ContainerEmptyState
} from '../styles'
import Label from 'src/UIComponents/Label/Label'
import { darkGray, darkerGray, smallText } from 'src/Styles/settings/Constants'

import PieChart from 'src/UIComponents/Charts/PieChart'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import ErrorStateCard from 'src/Components/ErrorStateCard/ErrorStateCard'

import ReloadIcon from 'src/Assets/icons/actions/reload.svg'
import Sales from 'src/Assets/icons/others/sales.svg'

import { allTogether } from 'src/Actions/kpi'

const HistoryByType = (props) => {
  const { color, groupedBy, request, allRequests, statusCode } = useSelector(
    ({
      custom,
      kpi: {
        period,
        allTogetherKPIs: { allRequests },
        transactions: { succeeded }
      }
    }) => ({
      ...succeeded,
      period,
      color: custom.color,
      allRequests,
      statusCode: succeeded.error && succeeded.error.status
    })
  )

  const isLoading = Boolean(allRequests || request)

  return (
    <CardStyled
      notData={!isLoading && groupedBy && groupedBy.length === 0}
      {...props}
    >
      <Label bold uppercase color={darkGray} fontSize={smallText}>
        Histórico por tipo de pagamento
      </Label>
      {!isLoading && (!groupedBy || (groupedBy && groupedBy.length === 0)) ? (
        <ContainerEmptyState>
          {statusCode === 404 && (
            <EmptyState
              iconSrc={Sales}
              iconColor={darkerGray}
              iconSize='small'
              description='Nenhum pagamento foi feito <br />até o momento :('
            />
          )}
          {statusCode !== 404 && (
            <ErrorStateCard
              iconSrc={ReloadIcon}
              iconColor={darkerGray}
              color={darkerGray}
              iconSize='small'
              action={allTogether}
            />
          )}
        </ContainerEmptyState>
      ) : (
        <PieChart
          color={color}
          width='100%'
          height={320}
          data={groupedBy}
          isLoading={isLoading}
        />
      )}
    </CardStyled>
  )
}

export default HistoryByType
