import React from 'react'
import { useSelector } from 'react-redux'

import {
  CardStyled,
  ContainerEmptyState } from '../styles'
import Label from 'src/UIComponents/Label/Label'
import { darkGray, darkerGray, smallText } from 'src/Styles/settings/Constants'

import BarChart from 'src/UIComponents/Charts/BarChart'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import ErrorStateCard from 'src/Components/ErrorStateCard/ErrorStateCard'

import ReloadIcon from 'src/Assets/icons/actions/reload.svg'
import Sales from 'src/Assets/icons/others/sales.svg'

import { allTogether } from 'src/Actions/kpi'

const SaleProgression = (props) => {
  const { color, histogram, request, allRequests, statusCode } = useSelector(
    ({
      custom,
      kpi: {
        period,
        allTogetherKPIs: { allRequests },
        transactions: { succeeded }
      }
    }) => ({
      ...succeeded,
      period,
      color: custom.color,
      allRequests,
      statusCode: succeeded.error && succeeded.error.status
    })
  )

  // verifica se existe algum horário com dados dentro do objeto para validar a estrutura do gráfico
  const histogramFiltered =
    histogram && histogram.filter((item) => item.value1 !== 0)
  const hasHistogram = Boolean(
    histogramFiltered && histogramFiltered.length > 0
  )
  const isLoading = Boolean(allRequests || request)

  return (
    <CardStyled notData={!isLoading && !hasHistogram} {...props}>
      <Label bold uppercase color={darkGray} fontSize={smallText}>
        Progressão Geral de Vendas
      </Label>
      {!isLoading && !hasHistogram ? (
        <ContainerEmptyState>
          {statusCode === 404 && (
            <EmptyState
              iconSrc={Sales}
              description='Ainda sem progressão geral de vendas.'
              iconColor={darkerGray}
              iconSize='small'
            />
          )}
          {statusCode !== 404 && (
            <ErrorStateCard
              iconSrc={ReloadIcon}
              iconColor={darkerGray}
              color={darkerGray}
              action={allTogether}
            />
          )}
        </ContainerEmptyState>
      ) : (
        <BarChart
          color={color}
          width='100%'
          layout='horizontal'
          height={252}
          data={histogram}
          isLoading={isLoading}
        />
      )}
    </CardStyled>
  )
}

export default SaleProgression
