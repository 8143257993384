/* eslint react/prop-types: 0 */
import React, { Fragment, useEffect, useState } from 'react'
import { darkerGray, normalText } from 'src/Styles/settings/Constants'
import { useDispatch, useSelector } from 'react-redux'

import Can from 'src/Helpers/Permission/Can'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import PropTypes from 'prop-types'
import { ReportButton } from 'src/Components/Report'
import Table from 'src/Components/Table/NewTable'
import Title from 'src/UIComponents/Title/Title'
import { TransferFilter } from 'src/Containers/Transfer/Filter'
import { TransferStatus } from 'src/Constants'
import { clamp } from 'lodash'
import { getTransfers } from 'src/Actions/transfers'
import { getTransfersTypeLabels } from 'src/Constants/TransfersTypeLabels'
import moment from 'moment'
import { toMoney } from 'src/Utils/Utils'
import { useQuery } from 'src/Utils/Hooks'

const TransferTable = ({ columns, onItemClick }) => {
  const dispatch = useDispatch()

  const {
    transfersRequesting: loading,
    transfersError: error,
    transfers,
    transfersTotalPages: totalPages,
    transfersPageSize: pageSize,
    transfersTotalItems: totalItems
  } = useSelector(({ transfers }) => transfers)

  const query = useQuery()
  const [filters, setFilters] = useState(query)
  const [search, setSearch] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const [resultsPerPage, setResultsPerPage] = useState(pageSize)
  const [sort, setSort] = useState('created_at:desc')

  useEffect(() => {
    if (loading) return

    dispatch(getTransfers({
      ...filters,
      limit: resultsPerPage,
      offset: currentPage * resultsPerPage,
      transfer_id: search,
      sort
    }))
  }, [filters, resultsPerPage, currentPage, search, sort])

  const handleFilterSubmit = (newFilters = {}) => {
    handleClearSearchInput()
    setFilters(newFilters)
  }

  const handleClearAllFilters = () => {
    setCurrentPage(0)
    setFilters({})
  }

  const handleSearchSubmit = value => {
    handleClearAllFilters()
    setSearch(value)
  }

  const handleClearSearchInput = () => {
    setCurrentPage(0)
    setSearch()
  }

  const onResultsPerPageChange = (value) => {
    setCurrentPage(0)
    setResultsPerPage(value)
  }

  const handlePageChange = page => {
    const newPage = clamp(page, 0, totalPages)
    setCurrentPage(newPage)
  }

  const handleSortChange = sorted => {
    setCurrentPage(0)
    const sort = sorted.length > 0 ? `${sorted[0].id}:${sorted[0].desc ? 'desc' : 'asc'}` : 'created_at:desc'
    setSort(sort)
  }

  return (
    <>
      <Grid.Row>
        <Grid.Col cols={10}>
          <Title bar small>
            <strong>Histórico</strong> de Transferências
          </Title>
        </Grid.Col>
        <Can do='create' on='ExportTransaction'>
          <Grid.Col cols={6}>
            <ReportButton type='transfers' filters={filters} results={transfers ? transfers.length : 0} />
          </Grid.Col>
        </Can>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={16}>
          <TransferFilter
            search={search}
            values={filters}
            searchPlaceholder='Buscar pelo ID da transferência'
            onSearchSubmit={handleSearchSubmit}
            onSearchClear={handleClearSearchInput}
            onFiltersApply={handleFilterSubmit}
            onFiltersClear={handleClearAllFilters}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row auto>
        <Table
          loading={loading}
          tableColumns={columns}
          tableData={transfers}
          totalPages={totalPages}
          totalItems={totalItems}
          pageSize={resultsPerPage}
          error={error}
          emptyState={{
            message: search ? 'Não encontramos esta transferência' : 'Nenhuma transferência encontrada de\n acordo com os filtros aplicados',
            description: search && 'Verifique se o ID que você buscou está correto',
            icon: search ? '/assets/icons/actions/search.svg' : '/assets/icons/banking/transfer.svg'
          }}
          manual
          showPageSizeOptions
          onResultsPerPageChange={onResultsPerPageChange}
          onItemClick={onItemClick}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          currentPage={currentPage}
          defaultSortBy={[
            {
              id: 'date',
              desc: true
            }
          ]} />
      </Grid.Row>
    </>
  )
}

TransferTable.defaultProps = {
  filters: {},
  columns: [
    {
      Header: 'Data de criação',
      accessor: 'date',
      width: '10%',
      Cell: ({ cell }) => {
        if (!cell.value) {
          return
        }
        return (<div>{cell.value.day} <br /> {cell.value.hour} </div>)
      },
      sortable: true
    },
    {
      Header: 'Data do pagamento',
      accessor: 'transfer_date',
      width: '10%',
      Cell: ({ cell }) => {
        if (!cell.value) {
          return
        }
        return (<div>{moment(cell.value).utc(false).format('DD/MM/YYYY')}</div>)
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: '10%',
      Cell: ({ cell }) => {
        const { value } = cell

        return (
          TransferStatus[value] &&
          <Label
            color={TransferStatus[value].color}
            bold
            uppercase
            overflow
          >
            {TransferStatus[value].label}
          </Label>
        )
      }
    },
    {
      Header: 'Favorecido',
      width: '20%',
      Cell: ({ cell }) => {
        const { values } = cell.row
        const { bank_account: bankAccount } = values
        const { holder_name: holderName } = bankAccount
        if (holderName) {
          return (
            <Label overflow fontSize={normalText} color={darkerGray}>{holderName}</Label>
          )
        }
        return <Label fontSize={normalText} color={darkerGray}>N/A</Label>
      }
    },
    {
      Header: 'Tipo',
      accessor: 'type',
      width: '15%',
      Cell: ({ cell }) => (
        <Label
          fontSize={normalText}
          color={darkerGray}
          overflow
        >
          {getTransfersTypeLabels(cell.value)}
        </Label>
      )
    },
    {
      Header: 'Meio de Recebimento',
      accessor: 'bank_account',
      width: '20%',
      Cell: ({ cell }) => {
        const { value: bankAccount } = cell
        const bankName = bankAccount ? bankAccount.bank_name : ''
        let routingNumber = bankAccount && bankAccount.routing_number

        if (bankAccount && bankAccount.routing_check_digit) {
          routingNumber += `-${bankAccount.routing_check_digit}`
        }

        return (
          <Fragment>
            <Label fontSize={normalText} color={darkerGray} bold>
              {bankName}
            </Label>
            {bankAccount &&
              <div>
                <Label fontSize={normalText} color={darkerGray}>
                  Ag: {routingNumber} Conta: {bankAccount.account_number}
                </Label>
              </div>}
          </Fragment>
        )
      }
    },
    {
      Header: 'Valor',
      accessor: 'amount',
      width: '10%',
      Cell: ({ cell }) => <Label fontSize={normalText} color={darkerGray}>{toMoney(cell.value)}</Label>
    }
  ],
  onItemClick: () => { /* noop */ }
}

TransferTable.propTypes = {
  columns: PropTypes.array,
  onItemClick: PropTypes.func
}

export {
  TransferTable as Table
}
