import React from 'react'
import { useSelector } from 'react-redux'

import {
  CardStyled,
  ContainerEmptyState
} from '../styles'

import Label from 'src/UIComponents/Label/Label'
import { darkGray, darkerGray, smallText } from 'src/Styles/settings/Constants'

import PieChart from 'src/UIComponents/Charts/PieChart'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import ErrorStateCard from 'src/Components/ErrorStateCard/ErrorStateCard'

import Like from 'src/Assets/icons/actions/like.svg'
import ReloadIcon from 'src/Assets/icons/actions/reload.svg'

import { allTogether } from 'src/Actions/kpi'

const FailedCapturing = (props) => {
  const { color, groupedBy, request, allRequests, statusCode } = useSelector(
    ({
      custom,
      kpi: {
        period,
        allTogetherKPIs: { allRequests },
        transactions: { failed }
      }
    }) => ({
      ...failed,
      period,
      color: custom.color,
      allRequests,
      statusCode: failed.error && failed.error.status
    })
  )

  const isLoading = Boolean(allRequests || request)
  return (
    <CardStyled
      notData={!isLoading && groupedBy && groupedBy.length === 0}
      {...props}
    >
      <Label bold uppercase color={darkGray} fontSize={smallText}>
        Vendas falhadas
      </Label>
      {!isLoading && (!groupedBy || (groupedBy && groupedBy.length === 0)) ? (
        <ContainerEmptyState>
          {statusCode === 404 && (
            <EmptyState
              iconSrc={Like}
              iconColor={darkerGray}
              iconSize='small'
              description='Quem bom! Nenhuma falha de<br />captura no momento :)'
            />
          )}
          {statusCode !== 404 && (
            <ErrorStateCard
              iconSrc={ReloadIcon}
              iconColor={darkerGray}
              color={darkerGray}
              iconSize='small'
              action={allTogether}
            />
          )}
        </ContainerEmptyState>
      ) : (
        <PieChart
          color={color}
          width='100%'
          height={320}
          data={groupedBy}
          isLoading={isLoading}
        />
      )}
    </CardStyled>
  )
}

export default FailedCapturing
