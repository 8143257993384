import React, { createContext, useState } from 'react'
import * as PropTypes from 'prop-types'

const CreateTransactionContext = createContext()

const CreateTransactionContextProvider = ({ children }) => {
  const [state, setState] = useState({
    transaction: null,
    success: null,
    error: null
  })

  return (
    <CreateTransactionContext.Provider
      value={{
        state,
        setState
      }}
    >
      { children }
    </CreateTransactionContext.Provider>
  )
}

CreateTransactionContextProvider.propTypes = {
  children: PropTypes.any
}

export { CreateTransactionContextProvider }
export default CreateTransactionContext
