import React from 'react'
import { Beacon } from '../../Components/OnboardingHints/Beacon'

export default [
  {
    title: 'Visão Geral',
    to: '/visao-geral',
    id: 'visao-geral',
    permissions: {
      hide: ['full_access_configuration']
    }
  },
  {
    title: 'Extrato',
    to: '/extrato',
    id: 'extrato',
    hint: <Beacon hint='menu-extrato' wrapperProps={{ top: '30px', right: '55px' }} />,
    permissions: {
      hide: ['full_access_configuration']
    }
  },
  {
    title: 'Vendas',
    to: '/vendas',
    id: 'vendas',
    permissions: {
      hide: ['full_access_configuration']
    }
  },
  {
    title: 'Transferências',
    to: '/transferencias',
    id: 'transferencias',
    permissions: {
      hide: ['full_access_configuration']
    }
  },
  {
    title: 'Lançamentos Futuros',
    to: '/lancamentos-futuros',
    id: 'lancamentos-futuros',
    hint: <Beacon hint='menu-lancamentos-futuros' wrapperProps={{ top: '30px', right: '100px' }} />,
    permissions: {
      hide: ['full_access_configuration']
    }
  },
  {
    title: 'Configurações',
    to: '/configuracoes',
    id: 'configuracoes',
    hint: <Beacon hint='menu-configuracoes' wrapperProps={{ top: '30px', right: '80px' }} />
  }
]
